import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router/index'
import { store } from './utils/store'

import axios from 'axios'
import qs from 'qs'


import Vant from 'vant';
import 'vant/lib/index.css';



import ElementPlus, { autocompleteEmits } from 'element-plus'
import 'element-plus/dist/index.css'

import * as echarts from 'echarts';

//封装请求
const myaxios = function(url,type,data={},headers){

	return new

	Promise((resolve) => {


		//判断
		if(type==="get" || type === "delete"){

			axios({
				method:type,
				url:url,
				params:data,
			}).then((result) => {

				resolve(result.data);

			});


		}else{


			axios({

				method:type,
				url:url,
				data:qs.stringify(data)
			}).then((result) => {

					// alert(type)

					resolve(result.data);

			});



		}


	});



}






const app = createApp(App)


app.config.globalProperties.myaxios = myaxios;
app.config.globalProperties.axios = axios;
// app.prototype.resetSetItem = asd
app.config.globalProperties.$echarts = echarts;
axios.defaults.timeout = 60000;

if(process.env.NODE_ENV == 'development'){
	// axios.defaults.baseURL = 'http:///192.168.1.134:8000'
	axios.defaults.baseURL = 'http://101.200.139.27:8000'
	// axios.defaults.baseURL = 'http://10.40.92.219:8000'
}else{
	axios.defaults.baseURL = 'http://101.200.139.27:8000'
}

app.use(router);
app.use(Vant);
app.use(ElementPlus)
app.use(store)

app.mount('#app')