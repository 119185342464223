<template>
    <div>
        
        <div>
    
            <van-notice-bar color="#1989fa" scrollable background="#ecf9ff" left-icon="info-o">
                    注：选项文字个数请勿过多,选项中不要填写半角单双引号和空格
                </van-notice-bar>
    
    
            <!-- <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane label="User" name="1">User</el-tab-pane>
                <el-tab-pane label="Config" name="2">Config</el-tab-pane>
                <el-tab-pane label="Role" name="3">Role</el-tab-pane>
                <el-tab-pane label="Task" name="fourth">Task</el-tab-pane>
            </el-tabs> -->
            <br><br><br>
    
            <el-radio-group @change="chflag" v-model="aflag">
                <el-radio-button :label="1">新视频试题上传</el-radio-button>
                <el-radio-button :label="2">已有视频试题上传</el-radio-button>
                <el-radio-button :label="3">视频替换</el-radio-button>
            </el-radio-group>
            
            
    
            <div v-if="aflag == 1">
                <h1>新视频试题上传</h1>
                <span style="color: #606266; padding-right: 12px;">请选择您要上传的位置&nbsp;</span>
    
                <el-cascader 
                placeholder="请选择上传位置"
                :props="{label:'name',value:'id',checkStrictly:true}"
                :options="menus"
                v-model="menus_value" 
                /><br><br>
    
                <div v-for="(item,key) in form">
    
                    <el-form :model="item">
    
                        <el-form-item :label="'问题'+(key+1)">
                            <el-input v-model="item.question" />
                        </el-form-item>
                        <div v-for="(j,k) in item.answer">
                        <el-form-item :label="'选项'+(k+1)">
                            <el-input v-model="j.text" />
                        </el-form-item>
                        </div>
                        <el-form-item label="正确答案">
                            
                            <span v-for="j in item.answer">
                                <el-radio style="padding-right: 10px;" v-model="item.answer_id" :label="j.id" size="large">选项{{j.id}}</el-radio>
        
                            </span>
                            
                            
                        </el-form-item>
                        <div style="text-align: center;">
                        <el-button @click="addAnswer(key)">
                            +&nbsp;添加选项
                        </el-button>
                        <el-button @click="delAnswer(key)">
                            -&nbsp;删除选项
                        </el-button>
                        </div>
                    </el-form>
    
                </div>
                <br>
                <el-button @click="addQuestion">+&nbsp;添加问题</el-button>
                <el-button @click="delQuestion">-&nbsp;删除问题</el-button>
                <br><br>
    
    
                <!-- <van-uploader>
                  <van-button @click="handleUploadForm(file)" icon="plus" type="primary">上传文件</van-button>
                </van-uploader> -->
    
    
                <!-- <el-upload
                    ref="upload"
                    class="upload-demo"
                    :on-success="(res,file)=>{successfile(res,file)}"
                    name="file"
                    :limit="1"
                    :on-exceed="handleExceed"
                    :on-remove="beforeRemove"
                    :before-upload="beforeAvatarUpload"
                    :http-request="nFile"
                    :headers="myHeaders"
                    :file-list="newlist"
                    multiple
                    >
                    <el-button  type="primary">点击上传文件</el-button>
                    
    
                    <template #tip>
                    
                    </template>
                </el-upload> -->
                
                <!-- action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" -->
                
                <!-- :on-preview="videoShow"
                :on-remove="beforeRemove" -->
                <el-upload
                    list-type="picture-card"
                    ref="upload"
                    class="upload-demo"
                    :on-success="(res,file)=>{successfile(res,file)}"
                    name="file"
                    :limit="1"
                    :on-exceed="handleExceed"
                    
                    :before-upload="beforeAvatarUpload"
                    :http-request="nFile"
                    :headers="myHeaders"
                    :file-list="newlist"
                    
                    multiple
                >
                
                    <el-icon><Plus /></el-icon>
                    <template #file="{ file }">
                        <van-circle
                            v-model:current-rate="percentage"
                            size="100%"
                            :speed="100"
                            :stroke-width="60"
                            :text="percentage + '%'"
                        />
                        <!-- {{ file.name }} -->
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="videoShow"
                            >
                                <el-icon><zoom-in /></el-icon>
                            </span>
            
                            <span                    
                                class="el-upload-list__item-delete"
                                @click="beforeRemove(file)"
                            >
                                <el-icon><Delete /></el-icon>
                            </span>
                        </span>
                    </template>
                </el-upload>
    
                <!-- <span>{{text}}</span> -->
                <!-- <el-progress v-show="text" :percentage="percentage"  /> -->
    
                <div style="text-align:center;">
                
                    <el-button @click="postForm">确认提交</el-button>
                    <el-button @click="delFrom">点击预存</el-button>
    
                </div>
    
            </div>
    
            <div v-if="aflag == 2">
                <h1>抱歉，该功能正在修复中</h1>
                <!-- <h1>已有视频试题上传</h1>
    
                <span style="color: #606266; padding-right: 12px;">请选择视频名称&nbsp;</span>
    
                
                <el-select v-model="video_name" filterable placeholder="请选择搜索视频名称">
                <el-option
                  v-for="item in video_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
                </el-select>
    
                <br><br>
    
    
    
                <div v-for="(item,key) in form">
    
                    <el-form :model="item">
    
                        <el-form-item :label="'问题'+(key+1)">
                            <el-input v-model="item.question" />
                        </el-form-item>
                        <div v-for="(j,k) in item.answer">
                        <el-form-item :label="'选项'+(k+1)">
                            <el-input v-model="j.text" />
                        </el-form-item>
                        </div>
    
                        <el-form-item label="正确答案">
                            <span v-for="j in item.answer">
                                <el-radio style="padding-right: 10px;" v-model="item.answer_id" :label="j.id" size="large">选项{{j.id}}</el-radio>
                            </span>
                        </el-form-item>
    
                        <div style="text-align: center;">
                        <el-button @click="addAnswer(key)">
                            +&nbsp;添加选项
                        </el-button>
                        <el-button @click="delAnswer(key)">
                            -&nbsp;删除选项
                        </el-button>
                        </div>
                    </el-form>
    
                </div>
                <br>
                <el-button @click="addQuestion">+&nbsp;添加问题</el-button>
                <el-button @click="delQuestion">-&nbsp;删除问题</el-button>
                <br><br>
    
    
    
                <div style="text-align:center;">
                
                    <el-button @click="postQfrom">确认提交</el-button>
                    <el-button @click="delFrom">点击预存</el-button>
    
                </div> -->
    
            </div>
    
            <div v-if="aflag == 3">
                <h1>抱歉，该功能正在修复中</h1>
                <h1>视频替换</h1>
                <span style="color: #606266; padding-right: 12px;">请选择您要替换的视频&nbsp;</span>
    
                <el-select v-model="video_name" filterable placeholder="请选择搜索视频名称">
                <el-option
                  v-for="item in video_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
                </el-select>
                <br><br>

                <el-upload
                    list-type="picture-card"
                    ref="upload"
                    class="upload-demo"
                    :on-success="(res,file)=>{successfile(res,file)}"
                    name="file"
                    :limit="1"
                    :on-exceed="handleExceed"
                    
                    :before-upload="beforeAvatarUpload"
                    :http-request="replaceFile"
                    :headers="myHeaders"
                    :file-list="newlist"
                    
                    multiple
                >
                
                    <el-icon><Plus /></el-icon>
                    <template #file="{ file }">
                        <van-circle
                            v-model:current-rate="percentage"
                            size="100%"
                            :speed="100"
                            :stroke-width="60"
                            :text="percentage + '%'"
                        />
                        <!-- {{ file.name }} -->
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="videoShow"
                            >
                                <el-icon><zoom-in /></el-icon>
                            </span>
            
                            <span                    
                                class="el-upload-list__item-delete"
                                @click="beforeRemove(file)"
                            >
                                <el-icon><Delete /></el-icon>
                            </span>
                        </span>
                    </template>
                </el-upload>
<!--     
                <el-upload
                    ref="upload"
                    class="upload-demo"
                    name="file"
                    :limit="1"
                    :file-list="imglist"
                    :on-exceed="handleExceed"
                    :on-remove="beforeRemove"
                    :before-upload="beforeAvatarUpload"
                    :http-request="replaceFile"
                    :headers="myHeaders"
                >
                <el-button  type="primary">点击上传文件</el-button>
    
                <template #tip>
                  
                </template>
                </el-upload> -->
    
                <el-progress ref="progress" v-show="text" :percentage="percentage"  />
    
            </div>
    
    
    
            
    
            
            
    
    
            <!-- :headers="myheaders" -->
            <!-- <el-upload
            class="upload-demo"
            action="http://192.168.2.74:8000/upload/"
            :on-success="(res,file)=>{successfile(res,file)}"
            :methods="post"
            name="file"
            :data="{'uid':this.uid}"
            :limit="1"
            :on-exceed="handleExceed"
            :on-remove="beforeRemove"
            :before-upload="beforeAvatarUpload"
    
        
    
              >
            <el-button  type="primary">点击上传文件</el-button>
            <template #tip>
              
            </template>
            </el-upload> -->
    
    
            
    
    
    
            <div v-show="isUploading">
                
            <el-progress
              :text-inside="true"
              :stroke-width="14"
              :percentage="percentage"
              :status = "percentage === 100"
            ></el-progress>
            </div>
    
            <el-dialog
                v-model="defShow"
                title="提示"
                width="30%"
            >
                <span>您有已保存的数据是否继续编辑？</span>
                <template #footer>
                <span class="dialog-footer">
                    <el-button @click="defShow = false">否</el-button>
                    <el-button type="primary" @click="fromUpdata"
                    >是</el-button
                    >
                </span>
                </template>
            </el-dialog>
    
    
            <el-dialog width="50%" v-model="select_video_show" destroy-on-close >
                <video style="width:100%;" :src="select_video_url" controls="controls"></video>
                <!-- <video width="500" height="500" :src="select_video_url" /> -->
            </el-dialog>
    
    
    
    
    
        </div>
    
        
    </div>	
    </template>
    
    
    <script>
    import { on } from "events";
    import { inject, onDeactivated } from "vue";
    import { Plus,Delete, ZoomIn } from '@element-plus/icons-vue'
    import { ElLoading } from 'element-plus'
    export default{
        inject:['reload'],
        // 注册组件
        components:{
            Plus,
            Delete,
            ZoomIn,
        },
        // 数据变量
        data(){
    
            return{
    
                // 下拉选择菜单
                menus:"",
                menus_value:"",
                // 表单
                form:[{
                    question:"",
                    answer:[{id:1,text:""},{id:2,text:""}],
                    answer_id:""
    
                }],
    
                filename:"",
                text:false,
                uid:localStorage.getItem("uid"),
                myHeaders: {'Access-Control-Allow-Origin':'*'},
                excelVisible: false,
                isIUploading:false,
                percenttage:0,
                msgdict:"",
                aflag:1,
    
                video_list:"",
                video_name:"",
                activeName:"",
                imglist:[],
                newlist:[],
    
                defShow:false,
    
                deflist:[],
    
                percentage:"",
                // 分片数量
                filecount:0,
    
                select_video_show:false,
                select_video_url:"http://101.200.139.27:8000/static/audit/"
    
               
            }
    
        },
    
    
    
    
        // 方法
        methods:{
    
            // 视频预览
            videoShow(){
                // console.log(this.filename)
    
                if(this.filename == "" || this.filename == null || this.filename == undefined){
                    this.$toast.fail("上传完成后可进行查看")
    
                    return false;
                }
                
                this.select_video_url = this.select_video_url + this.filename
                this.select_video_show = true
                
            },
    
            // 遇存方法
            delFrom(){
                // alert("退出")
                var menus
                if(this.aflag == 1){
                    menus = JSON.stringify(this.menus_value)
                }else{
                    menus = this.video_name
                }
                this.myaxios("/jifen/","post",{"uid":localStorage.getItem("uid"),"question":JSON.stringify(this.form),"menuList":menus,"filename":this.filename,"flag":this.aflag})
                // this.myaxios("/jifen/","post",{"uid":localStorage.getItem("uid"),"question":JSON.stringify(this.form),"menuList":JSON.stringify(this.menus_value),"filename":this.filename,"flag":3})
                .then(data=>{
                    console.log(data)
                    if(data.code == 200){
    
                        this.form = [{question:"",answer:[{id:1,text:""},{id:2,text:""}],answer_id:""}]
                        this.menus_value = ""
                        this.filename = ""
                        // this.$refs.upload.clearFiles()
                        // this.$refs["resupload"].clearFiles()
                        this.newlist = []
                        this.text = false
                        this.video_name = ""
                        this.$toast.success("存放成功")
                        this.$refs.upload.clearFiles()
                        
                        // location.reload();
                        // self.location.reload();
                        
    
                    }else{
                        this.$toast.fail("抱歉")
                    }
                })
            },
    
            // 获取草稿内容
            getDFrom(){
                this.myaxios("/jifen/","get",{"uid":localStorage.getItem("uid")})
                .then(data=>{
                    console.log(data)
                    if(data.code == 200){
                        this.defShow = true
                        console.log(data.data)
                        console.log(data)
                        var lists = data.data
                        for(let i=0; i<lists.length; i++){
    
                            // alert(lists[i].answer)
                            console.log(lists[i].answer)
    
                            lists[i].answer = JSON.parse(lists[i].answer)
                        }
    
                        // this.qlist = lists
                        
                        this.deflist = lists
                        console.log(this.deflist)
    
                    }else{
                        this.defShow = false
    
                    }
                })
            },
    
            // 确实编写上次提交的数据
            fromUpdata(){
                var answer
                var alist = []
                var lform = {}
                this.form.shift()
                this.newlist = []
                for(let item in this.deflist){
                    lform = {}
                    alist = []
                    this.newlist = []
                    for(let j in this.deflist[item]["answer"]){
                        answer = ""
                        answer = {
                                    id:this.deflist[item]["answer"][j]["id"],
                                    text:this.deflist[item]["answer"][j]["text"]
                                }
                        
                        alist.push(answer)
                        
                    }
                    lform["answer"] = alist
                    lform["question"] = this.deflist[item]["content"]
                    lform["answer_id"] = this.deflist[item]["answer_id"]
                    this.form.push(lform)
                    if(this.deflist[item]["video_name"] == ""){
                        this.newlist=[]
                    }
                    else{
                         this.newlist.push({name:this.deflist[item]["video_name"],raw:{name:this.deflist[item]["video_name"],type:"video/mp4"}})
                        this.filename = this.deflist[item]["video_name"]
                    }
                    
                    this.aflag = this.deflist[item]["flag"]
    
                    if(this.deflist[item]["flag"] == 1){
                        // alert("1")
                        // alert(this.deflist[item]["top_id"])
                        this.menus_value = JSON.parse(this.deflist[item]["top_id"])
                    }
                    else if(this.deflist[item]["flag"] == 2){
                        // alert("2")
                        this.video_name = this.deflist[item]["top_id"]
                    }
                    
                    
                }
                console.log(this.form)
                console.log(this.deflist)
    
                this.defShow = false
    
            },
    
    
            handleClick(a,b){
                console.log(a)
                console.log(a.props.name)
                console.log("xxxxxxxxx")
                console.log(b)
                this.aflag = a.props.name
    
            },
    
    
            // 已存在视频上传问题
            postQfrom(){
    
    
                if(this.video_name === ""){
                    this.$toast.fail("抱歉，您菜单未选择")
                    return
                }
    
                for(var item in this.form){
    
                    console.log(this.form[item])
    
                    if(this.form[item].question === "" || this.form[item].answer_id === ""){
    
                        this.$toast.fail("抱歉，请填写完整信息")
                        return
    
                    }
    
                    var flag = 0
    
                    for(var i in this.form[item].answer){
    
                        if(this.form[item].answer[i].text === ""){
    
                            this.$toast.fail("抱歉，答案未填写完整")
                            return
    
                        }
                        if(this.form[item].answer[i].id != this.form[item].answer_id){
    
                                flag+=1
    
                                console.log(this.form[item].answer[i].text)
    
                                console.log(this.form[item].answer_id)
                                
                        }
    
                    }
    
    
    
                    if(flag != (this.form[item].answer.length -1)){
    
                        this.$toast.fail("抱歉，选项中未存在该答案")
    
                        return 
    
                    }
    
    
                    if(this.form[item].answer.length <= 1){
    
    
                        this.$toast.fail("抱歉，至少有两个选项")
                        return
    
                    }
    
    
                }
    
    
    
    
                this.myaxios("/upload/","put",{"uid":localStorage.getItem("uid"),"question":JSON.stringify(this.form),"menuList":this.video_name,"flag":2})
    
                .then(data=>{
    
    
                    console.log(data)
                    this.form = [{question:"",answer:[{id:1,text:""},{id:2,text:""}],answer_id:""}]
                    this.video_name = ""
                    this.$toast.success("上传成功，请等待审核")
    
    
    
                })
    
            },
    
    
            chflag(){
                
                console.log(this.aflag)
                
    
            },
            
            //要替换的视频文件
            newfile(file){
    
                    this.text = false
                    this.myaxios("/uploadinfo/","put",{"filename":file.file.name,"flag":1})
    
                    .then(res=>{
    
                        console.log(res)
    
                        if(res.code == 403){
    
                            this.$toast.fail(res.msg)
                            this.text = false
                            this.$refs.upload.clearFiles()
                            console.log(res.msg)
                            this.flag = 0
                            return false;
                            
                        }else if(res.code === 200){
    
                            this.text = true
                            this.flag = 0
    
                            let data = new FormData()
                            data.append('file',file.file)
                            data.append('event',1)
                            data.append("uid",localStorage.getItem("uid"))
    
    
                            console.log(file.file)
                            console.log(data)
    
                            const axiosinstance = this.axios.create({withCredentials:false})
    
                            axiosinstance({
    
                                method:'post',
                                url:'http://101.200.139.27:8000/upload/',
                                // url:'http://127.0.0.1:8000/upload/',
                                data:DataTransferItemList,
                                //文件上传的回调
                                onUploadProgress:progressEvent=>{
                                    let process = (progressEvent.loaded / progressEvent.total * 100 | 0)
                                    // this.progress = `上传进度：${process}%`
                                    console.log(process)
                                    this.percentage = process
                                }
    
    
    
                            }).then(data=>{
                                
    
                                if(data.data.code === 403){
                                    this.text = false
                                    this.$toast.fail(data.data.msg)
    
                                }else{
                                    this.text = true
                                    this.filename = data.data.filename
                                    // this.$refs["resupload"].clearFiles()
                                    console.log(this.$refs)
                                    this.$refs.upload.clearFiles()
                                    console.log(this.$refs)
    
                                    this.$toast.success("文件上传成功")
                                }
    
                            })
                        }
                    
                    })
    
            },
    
    
    
            chunkfile(filename,chunk,file,filecount,event){
                // 分片上传
                let data = new FormData()
                data.append('file',file)
                data.append('filename',filename)
                data.append('chunk',chunk)
                data.append("is_merge",0)
                data.append("event",event)
                console.log(chunk,filename,file,filecount,event)
    
    
    
                // return 
                this.axios({
                    // url:"http://192.168.2.74:8002/SalesVideoHanlder/",
                    url:"http://101.200.139.27:8000/upload/",
                    // url:"http://10.40.92.219:8000/upload/",
                    method:"post",
                    // headers:{'Content-Type': 'multipart/form-data'},
                    data:data
                }).then(result=>{
                    this.filecount++;
                    console.log("filecount",this.filecount,chunk)
                    // this.progressFlag = true
                    // this.percentage = (100/ (filecount-1)) * chunk
                    this.percentage = parseInt((this.filecount/filecount) * 100)
                    // console.log(parseInt(result.data.chunk),filecount - 2)
                    if(parseInt(this.filecount) == filecount){
                        console.log("合并时的数量与总数",this.filecount,filecount)
                        this.mergefile(filename,event)
                    }
                })
            },
    
            mergefile(filename,event){
                // 合并文件
                this.myaxios("/upload/","post",{"filename":filename,"is_merge":"2","event":event,"oldfile":this.video_name,"uid":localStorage.getItem("uid")})
                .then(data=>{
                    console.log("合并回调",data)
                    if(data.code == 200){
                        this.percentage = 100
                        this.filecount = 0
                        // this.progressFlag = false
                        this.filename = data.filename
                        this.$message({
                            showClose: true,
                            message:"文件上传成功",
                            type: 'success',
                        })
                    }
                })
            },
    
    
            oneFile(file,event){
    
                this.text = true
                let data = new FormData()
                console.log(file.file)
                data.append('file',file.file)
                data.append('event',event)
                data.append("oldfile",this.video_name)
                data.append("uid",localStorage.getItem("uid"))
                data.append("is_merge","o")
    
    
                const axiosinstance = this.axios.create({withCredentials:false})
    
                axiosinstance({
    
                    method:'post',
                    url:'http://101.200.139.27:8000/upload/',
                    // url:"http://10.40.92.219:8000/upload/",
                    // url:'http://127.0.0.1:8000/upload/',
                    data:data,
                                // 文件上传的回调
                    onUploadProgress:progressEvent=>{
                        let process = (progressEvent.loaded / progressEvent.total * 100 | 0)
                                    // this.progress = `上传进度：${process}%`
                        console.log(process)
                        this.percentage = process
                    }
    
                }).then(data=>{
                                
                    if(data.data.code === 403){
                        // this.flag = 1
                        this.$toast.fail(data.data.msg)
                    }else{
                        // this.text = "上传成功"
                        this.text = true
                        this.filename = data.data.filename
                        this.$toast.success("文件上传成功")
    
                    }
    
                })
    
    
                return 
                // 100M以下单文件上传
                // this.soure = this.axios.CancelToken.source()
                // let data = new FormData()
                // data.append('file',file.file)
                // // data.append('filename',filename)
                // data.append('is_merge',1)
                // this.progressFlag = true
                // console.log(this.progressFlag)
                // const axiosinstance = this.axios.create({withCredentials:false})
                //     axiosinstance({
                //         method:'post',
                //         url:'http://101.200.139.27:8002/SalesVideoHanlder/',
                //         // url:'http://192.168.2.74:8002/SalesVideoHanlder/',
                //         data:data,
                //         cancelToken:this.soure.token,
                //                 // 文件上传的回调
                //         onUploadProgress:progressEvent=>{
                //             let process = (progressEvent.loaded / progressEvent.total * 100 | 0)
                //             this.percentage = process                        
    
                //         }
    
                //     }).then(data=>{
                //         if(data.data.code === 403){
                //             this.$toast.fail(data.data.msg)
                //         }else{
                //             this.progressFlag = false
                //             this.percentage = 0
                //             this.filename = data.data.filename
                //             this.$message({
                //                 showClose: true,
                //                 message:"文件上传成功",
                //                 type: 'success',
                //             })
                //         }
    
                //         }).catch(error=>{
                //             console.log(error)
                //     })
            },
    
    
            // 新视频新视试题文件上传
            nFile(file){
    
                    this.text = ""
                    this.myaxios("/uploadinfo/","put",{"filename":file.file.name,"flag":1})
    
                    .then(res=>{
    
                        if(res.code == 403){
                            this.$toast.fail(res.msg)
                            this.text = false
                            this.$refs['upload'].clearFiles()
                            this.newlist = []
                            return
                        }else if(res.code === 200){
    
                            this.text = true
                            const addfileSzie = 100 * 1024 * 1024 // 100M
                            const chunkSize = 10 * 1024 * 1024 // 10M
                            var fileSize = file.file.size
                            var fileName = file.file.name
                            console.log("size--------",fileSize,addfileSzie,chunkSize)
                            if(fileSize < addfileSzie){
                                // 普通上传
                                this.oneFile(file,1)
                            }else{
                                // 分片上传
                                const chunkCount = Math.ceil(fileSize / chunkSize) // 分片数量
                                var num = 0
                                for(let i = 1;i <= chunkCount; i++){
                                    var start = num * chunkSize
                                    var end = Math.min(fileSize, start + chunkSize) // Math.min 返回最小值
                                    var chunk = file.file.slice(start,end)
                                    // console.log("isc--------",file.slice(start,end))
                                    this.chunkfile(fileName,num,chunk,chunkCount,1)
                                    num+=1
                                }
                            }
    
                            return 
    
    
    
    
    
                            this.text = true
    
                            let data = new FormData()
                            console.log(file.file)
                            data.append('file',file.file)
                            data.append('event',1)
                            data.append("oldfile",this.video_name)
                            data.append("uid",localStorage.getItem("uid"))
    
    
                            const axiosinstance = this.axios.create({withCredentials:false})
    
                            axiosinstance({
    
                                method:'post',
                                url:'http://101.200.139.27:8000/upload/',
                                // url:'http://127.0.0.1:8000/upload/',
                                data:data,
                                // 文件上传的回调
                                onUploadProgress:progressEvent=>{
                                    let process = (progressEvent.loaded / progressEvent.total * 100 | 0)
                                    // this.progress = `上传进度：${process}%`
                                    console.log(process)
                                    this.percentage = process
                                }
    
                            }).then(data=>{
                                
    
                                if(data.data.code === 403){
                                    // this.flag = 1
                                    this.$toast.fail(data.data.msg)
    
                                }else{
                                    // this.text = "上传成功"
                                    this.text = true
                                    this.filename = data.data.filename
                                    this.$toast.success("文件上传成功")
    
    
                                }
    
                            })
                        }
                    
                    })
    
    
            },
    
    
    
            replaceFile(file,lists){
    
                    console.log(lists)
                    console.log(this.imglist)
    
                    if(this.video_name == ""){
                        this.$toast.fail("请先选择视频")
                        // this.flag = 2
                        console.log(this.$refs.upload)
                        this.$refs.upload.clearFiles()
                        this.imglist = []
                        console.log(this.imglist)
                        return
                    }
    
                    this.text = ""
                    this.myaxios("/uploadinfo/","put",{"filename":file.file.name,"flag":2})
    
                    .then(res=>{
    
                        if(res.code == 403){
    
                            this.$toast.fail(res.msg)
                            this.text = "上传失败"
                            this.$refs.upload.clearFiles()
                            this.imglist = []
                            console.log(this.$refs.upload)
    
                            console.log(res.msg)
                            // this.flag = 2
                            return false;
                            
                        }else if(res.code === 200){
    
    
                            this.text = true
                            const addfileSzie = 100 * 1024 * 1024 // 100M
                            const chunkSize = 10 * 1024 * 1024 // 10M
                            var fileSize = file.file.size
                            var fileName = file.file.name
                            console.log("size--------",fileSize,addfileSzie,chunkSize)
                            if(fileSize < addfileSzie){
                                // 普通上传
                                this.oneFile(file,2)
                            }else{
                                // 分片上传
                                const chunkCount = Math.ceil(fileSize / chunkSize) // 分片数量
                                var num = 0
                                for(let i = 1;i <= chunkCount; i++){
                                    var start = num * chunkSize
                                    var end = Math.min(fileSize, start + chunkSize) // Math.min 返回最小值
                                    var chunk = file.file.slice(start,end)
                                    // console.log("isc--------",file.slice(start,end))
                                    this.chunkfile(fileName,num,chunk,chunkCount,2)
                                    num+=1
                                }
                            }
    
                            return 
    
    
    
    
                            this.text = "上传中"
                            // this.flag = 2
    
                            let data = new FormData()
                            data.append('file',file.file)
                            data.append('event',2)
                            data.append("oldfile",this.video_name)
                            data.append("uid",localStorage.getItem("uid"))
    
    
                            console.log(file.file)
                            console.log(data)
    
                            const axiosinstance = this.axios.create({withCredentials:false})
    
                            axiosinstance({
    
                                method:'post',
                                url:'http://101.200.139.27:8000/upload/',
                                // url:'http://127.0.0.1:8000/upload/',
                                data:data
    
                            }).then(data=>{
                                
    
                                if(data.data.code === 403){
                                    this.$toast.fail(data.data.msg)
    
                                }else{
                                    this.text = "上传成功"
                                    this.filename = data.data.filename
                                    console.log("************")
                                    console.log(this.$refs)
                                    this.$refs.upload.clearFiles()
                                    this.imglist = []
                                    console.log(this.$refs)
                                    this.$toast.success("上传成功请等待审核")
                                }
    
                            })
                        }
                    
                    })
    
    
            },
    
    
            beforeAvatarUpload(file){
    
                this.flag = 0
    
                if(['video/mp4'].indexOf(file.type) == -1){
    
                    this.$toast.fail("请上传正确的视频格式(mp4)。")
    
                    return false;
    
                }
    
            },
    
    
            // 提交资料
            postForm(){
                console.log(this.filename)
                console.log(this.form)
                console.log(this.menus_value)
                console.log(this.$refs.upload)

    
                if(this.filename === ""){
                    this.$toast.fail("抱歉，您文件未上传")
                    return
                }
                if(this.menus_value === ""){
                    this.$toast.fail("抱歉，您菜单未选择")
                    return
                }
    
                for(var item in this.form){
    
                    console.log(this.form[item])
    
                    if(this.form[item].question === "" || this.form[item].answer_id === ""){
    
                        this.$toast.fail("抱歉，请填写完整信息")
                        return
    
                    }
    
                    var flag = 0
    
                    for(var i in this.form[item].answer){
    
                        if(this.form[item].answer[i].text === ""){
    
                            this.$toast.fail("抱歉，答案未填写完整")
                            return
    
                        }
                        if(this.form[item].answer[i].id != this.form[item].answer_id){
    
                                flag+=1
    
                                console.log(this.form[item].answer[i].text)
    
                                console.log(this.form[item].answer_id)
    
                                
                        }
    
                    }
    
    
    
                    if(flag != (this.form[item].answer.length -1)){
    
                        this.$toast.fail("抱歉，选项中未存在该答案")
    
                        return 
    
                    }
    
    
                    if(this.form[item].answer.length <= 1){
    
    
                        this.$toast.fail("抱歉，至少有两个选项")
                        return
    
                    }
    
    
                }


                const loading = ElLoading.service({
                    lock: true,
                    text: '😅努力上传中。。。',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
    
    
                 this.myaxios("/upload/","put",{"uid":localStorage.getItem("uid"),"question":JSON.stringify(this.form),"menuList":JSON.stringify(this.menus_value),"filename":this.filename,"flag":1})
    
                .then(data=>{
    
                    loading.close()

                    
                    if(data.code == 403){
                        this.$toast.success(data.msg)
                    }else{
                        this.form = [{question:"",answer:[{id:1,text:""},{id:2,text:""}],answer_id:""}]
                        this.menus_value = ""
                        this.filename = ""
                        // this.$refs.upload.clearFiles()
                        // this.$refs["resupload"].clearFiles()
                        this.$refs.upload.clearFiles()
                        this.newlist = []
                        this.text = false
                        // location.reload();
                        // self.location.reload();
                        this.$toast.success("上传成功，请等待审核")
                    }

                    console.log(data)
                    
                    
    
    
    
                })
    
            },
    
            beforeRemove:function(file){
    
                if(this.filename == "" || this.filename == null || this.filename == undefined){
                    this.$toast.fail("上传完成后可进行删除")
    
                    return false;
                }
    
                 if(['video/mp4'].indexOf(file.raw.type) == -1){
    
                    this.$toast.fail("请上传正确的视频格式(mp4)。")
    
                    return false;
    
                }
                
                this.text = false
    
                this.filename = ""
                this.newlist = []
    
                console.log(file.raw.name)
    
                this.myaxios("/upload/","delete",{"filename":file.raw.name})
    
                .then(data=>{
    
    
                    if(data.code == 200){
    
                        this.$toast.success("删除成功")
                        this.progressFlag=false
                        this.progressPercent=0
                        this.text = false
                    } 
                    else{
    
                        this.$toast.fail("删除失败")
    
                    }
    
                })
    
                // this.$toast.fail("删除成功")
    
            },
    
    
            handleExceed(){
    
                this.$toast.success("抱歉，最多上传一个文件")
    
            },
    
    
    
            // 文件
            successfile(res,file){
    
                console.log(res,file)
                if(res.code === 403){
                    this.$toast.fail(res.msg)
                }
                else{
                    this.$toast.success(res.msg)
                    this.filename = res.filename
                    console.log(this.filename)
                }
                
    
            },
    
    
            //添加问题字段
            addQuestion:function(){
                var dicts = {
                    question:"",
                    answer:[{id:1,text:""},{id:2,text:""}],
                    answer_id:""
                }
    
                this.form.push(dicts)
            },
    
            // 添加答案字段
            addAnswer:function(k){
    
                console.log(k)
    
                var ks = this.form[k].answer.length
    
                var dicts = {id:ks+1,text:""}
    
                this.form[k].answer.push(dicts)
    
            },
    
            // 删除答案字段
            delAnswer:function(k){
    
                // var index = this.form[k].answer.indexOf(k)
    
                // console.log(this.form[k].answer.length)
    
                if(this.form[k].answer.length === 1){
    
                    this.$toast.fail("不能删了，再删就没有了")
    
                    return
    
                }else{
                    console.log(this.form[k].answer)
                    this.form[k].answer.splice((this.form[k].answer.length)-1,1)
                }
    
                // console.log(index)
    
                
    
            },
    
            // 删除问题字段
            delQuestion:function(){
    
                if(this.form.length === 1){
    
                    return
    
                }else{
    
                    this.form.splice((this.form.length)-1,1)
                }
    
                // console.log(index)
    
            },
    
            // 下拉菜单请求
            getmenus:function(){
    
                this.myaxios("/menumsg/","delete")
    
                .then(data=>{
    
                        this.menus = data.data
    
                })
    
            },
    
            getvideo_name(){
                this.myaxios("/menuaction/","get",{"uid":localStorage.getItem("uid")})
                .then(data=>{
                    this.video_list = data.msg
                })
            },
    
    
            beforeunloadFn(e) {
                console.log('刷新或关闭')
                // this.delFrom()
            // ...
            }
    
    
            
        },
        // 生命周期钩子函数
        // 用来在一个实例被创建之后执行
        created(){
    
            
    
            window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    
        },
        //模板中的html渲染到了html页面中，一般用作于axios请求
        mounted(){
            this.aflag = 1
            // this.filename = "123"
            // this.newlist.push({"123":123})
            this.getmenus()
            this.getvideo_name()
            this.getDFrom()
            
        },
    
        destroyed() {
            window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
        }
    
        
    
        
    
    }
    
    </script>
    
    <style scoped>
    
    /*@import url("../assets/style.css");*/
    
    </style>